import { abtservice } from 'public/src/services/abt'
/**
 * 神策abt信息上报修改
 * @param {*} params 神策需要上报的abt，多个abt逗号拼接
 */
export const scAbtReportData = (params) => {
  if (!params) return []
  const scPoskeys = params.split(',').map(i => {
    const abts = i.split('_')
    let poskey = ''
    if (abts.length && abts[0]) {
      poskey = /^(RM|SM)/.test(abts[0]) ? abts[0].slice(2) : abts[0]
    }
    return poskey
  }).filter(i => !!i)

  // eslint-disable-next-line @shein-aidc/abt/abt
  const { sc } = abtservice.getUserAbtResultForAnalysis({ posKeys: scPoskeys.join() })
  return sc && sc.split(',') || []
}

/**
 * 站外跳转缺失resource_content兜底
 */
export const resourceContentBackup = () => {
  const path = location.pathname
  let matchObj
  matchObj = path.match(/\/daily-new.html$/)
  if (matchObj) return 'Special_dailyNew'

  matchObj = path.match(/\/.+-c-(\d+)\.html$/)
  if (matchObj) return `real_${matchObj[1]}`

  matchObj = path.match(/\/((\w+)\/)?.+-sc-([A-Za-z0-9]+)\.html$/)
  if (matchObj) return `itemPicking_${matchObj[3]}`

  matchObj = path.match(/\/([a-z]+-picks|romwe-[a-z]+|promo-discount).html$/)
  if (matchObj) return 'Special_SheinPicks'

  matchObj = path.match(/\/flash-sale.html$/)
  if (matchObj) return 'Special_flashSale'

  return ''
}
