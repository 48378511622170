// import { abtservice } from 'public/src/services/abt'
(function () {
    try {
      /**
       * sw + workbox 最低支持环境判断
       * serviceWorker + URLSearchParams.sort -->  (chrome 61+, safari 11.1+ [iOS 11.3+] firefox 54+ edge 17+ ...)
       * 能覆盖 request response url fetch caches async class 解构赋值 延展运算符 等等的兼容性支持
       * 注意 [不要] 在 sw 内使用可选链(chrome 80+)等高阶语法
       */
      if (!('serviceWorker' in navigator)) return
      new URLSearchParams().sort()
    } catch (error) {
      return
    }

    const SHELL_MODE = Promise.all([
      /* abt 放量 & 依赖 API 检查 (chrome 66+, safari 12.1+ [iOS 12.2+] firefox 57+ edge 17+ ...) */
      // abtservice.getUserAbtResult({ posKeys: 'AppShell' }).then(abtInfo => abtInfo.AppShell?.param === 'on' || Promise.reject('AppShell abt off')),
      (async () => new ReadableStream({ start() { } }))(),
      (async () => new AbortController())(),
      // 先关闭shell
      (Promise.reject(false))
    ]).then(() => true).catch(() => false)
  
    function handleServiceWorkerRegistration() {
      requestIdleCallback(async () => {
        const { langPath } = gbCommonInfo
        const swFile = await SHELL_MODE
          ? langPath + '/sw-runtime-cache-shell.js'
          : langPath + '/sw-runtime-cache.js'
        try {
          await window.swHolder
          const registration = await navigator.serviceWorker.register(swFile)
          await registration.update()
          window.dispatchEvent(new Event('SW_Loaded'))
          window.SW_Loaded = true
          console.log('ServiceWorker 注册成功。', registration)
        } catch (error) {
          console.error('ServiceWorker 注册失败。', error)
        }
      })
    }

    // 如果在添加事件监听器前 load 事件已经被触发
    if (document.readyState === 'complete') {
      setTimeout(handleServiceWorkerRegistration, 0)
    } else {
      window.addEventListener('load', handleServiceWorkerRegistration)
    }
})()
