$(function () {
  $(document).on('click', '.j-left-side-menu a', function () {
    var _href = $(this).attr('href')
    if (_href.indexOf('javascript') < 0 && _href.trim()) {
      var cRegx = /.+-c-(\d+).+/
      var cat_id = cRegx.exec(_href)
      // var e_l_n = ''

      if (cat_id) {
        cat_id = cat_id[1]
        // e_l_n = cat_id
      }
    }
  })

  $(document).on('click', function (e) {
    var $target = $(e.target)
    var eventLabel = ''
    var eventAction = ''
    var eventCategory = '导航栏'
    var footerDom = document.getElementsByClassName('j-index-footer')[0]
    if ($target.is('.j-search-form input')) {
      eventAction = 'EditSearch'
    } else if ($target.is('.j-search-cancel') || $target.is('.j-search-cancel .icon-left')) {
      eventAction = 'CancleSearch'
    } else if ($target.is('.j-go-wishlist')) {
      eventAction = 'ClickWishlist'
    } else if ($target.is('.j-header-bag, .j-header-bag .sui_icon_nav_shoppingbag_24px, .j-header-bag .dt-svg-bag, .j-header-bag .j-header-cart-num')) {
      if (typeof sa_nav !== 'undefined') {
        if (!footerDom || !footerDom.contains(e.target)) {
          // 底部tab 不发送 click_top_site_bag
          sa_nav.clickTopBag()
        }
      }
    }
  })
})

/* var GB_nav_analysis_obj = {
  search: function (params) {
    ga(GB_ANALYSIS_GA_SEND, {
      hitType: 'event',
      eventCategory: '导航栏',
      eventAction: 'GoToResult',
      eventLabel: (params && params.label) || 0
    })
    if (typeof sa_nav !== 'undefined') {
      sa_nav.gotoTopSearch(params ? params.label : '')
    }
  }
} */
var sa_nav = {
  exposeTimeout: -1,
  gotoTopSearch: function (val) {
    sa(
      'send',
      {
        activity_name: 'click_top_site_search',
        activity_param: { search_content: val }
      },
      { beacon: 1 }
    )
  },
  clickTopBag: function () {
    var param = $('.j-header-cart-num:first').text() || '0'
    sa('send', {
      activity_name: 'click_top_site_bag',
      activity_param: { bag_goods_count: param.trim(), type: 1, abtest: '' }
    })
  },
  bindEvent: function () {
    if (typeof SaPageInfo !== 'undefined' && SaPageInfo.page_id == 1) return
    $(document).on('click', '.sa-side-menu a', function () {
      var paramOption = [
        {
          pitPos_value: '',
          name_value: '',
          pitPos_key: 'first_menu_hole',
          name_key: 'first_menu_content'
        },
        {
          pitPos_value: '',
          name_value: '',
          pitPos_key: 'second_menu_hole',
          name_key: 'second_menu_content'
        },
        {
          pitPos_value: '',
          name_value: '',
          pitPos_key: 'third_menu_hole',
          name_key: 'third_menu_content'
        },
        {
          pitPos_value: '',
          name_value: '',
          pitPos_key: 'fourth_menu_hole',
          name_key: 'fourth_menu_content'
        }
      ]
      var urls = $(this).parents('ul')
      var $titleDom = $(this)
      for (let i = 0, length = urls.length; i <= length - 1; i++) {
        if ($titleDom.get(0).tagName == 'A') {
          paramOption[length - 1 - i].pitPos_value = $titleDom.parent().index() + 1
          paramOption[length - 1 - i].name_value = $titleDom.text().trim()
        }
        $titleDom = $(urls[i]).prev()
      }
      if (urls.length == 0) {
        paramOption[0].pitPos_value = 1
        paramOption[0].name_value = $titleDom.text().trim()
      } else {
        paramOption[0].pitPos_value += 1
      }
      var params = {}
      for (let i = 0; i < paramOption.length; i++) {
        if (paramOption[i].name_value != '') {
          params[paramOption[i].pitPos_key] = paramOption[i].pitPos_value
          params[paramOption[i].name_key] = paramOption[i].name_value
        }
      }
      sa('send', {
        activity_name: 'click_navigation_menu',
        activity_param: params
      })
    })
  }
}
$(function () {
  sa_nav.bindEvent()
})
