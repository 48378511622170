/**
 * @ Author: wjx
 * @ Create Time: 2020-12-09 10:38:50
 * @ Modified by: Your name
 * @ Modified time: 2020-12-24 18:57:31
 * @ Description: APP下载地址
 */
const sheinAppStore = 'itms-appss://apps.apple.com/app/id878577184'
const sheinAppUrlIos = {
  'mru': 'https://apps.apple.com/ru/app/shein-fashion-shopping-online/id878577184',
  'pwde': 'https://apps.apple.com/de/app/shein-shopping-und-fashion/id878577184',
  'pwit': 'https://apps.apple.com/it/app/shein-moda-e-shopping/id878577184',
  'pwfr': 'https://apps.apple.com/fr/app/shein-vente-privee-mode-femme/id878577184',
  'mth': 'https://apps.apple.com/th/app/shein-fashion-shopping-online/id878577184',
  'mtw': 'https://apps.apple.com/tw/app/shein-fashion-shopping-online/id878577184',
  'mhk': 'https://apps.apple.com/hk/app/shein-fashion-shopping-online/id878577184?l=en',
  'pwau': 'https://apps.apple.com/au/app/shein-fashion-shopping-online/id878577184',
  'pwuk': 'https://apps.apple.com/gb/app/shein-fashion-shopping-online/id878577184',
  'pwmx': 'https://apps.apple.com/mx/app/shein-fashion-online-shopping/id878577184',
  'default': 'https://itunes.apple.com/cn/app/shein-fashion-shopping/id878577184?mt=8'
}
const rwAppStore = 'itms-appss://apps.apple.com/app/id1080248000'
const rwAppUrlIos = {
  'rwmfr': 'https://itunes.apple.com/fr/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
  'rwmes': 'https://itunes.apple.com/es/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
  'rwmde': 'https://itunes.apple.com/de/app/romwe-shopping-fashion-clothing/id1080248000?mt=8',
  'default': 'https://itunes.apple.com/us/app/romwe-shopping-fashion-clothing/id1080248000?mt=8'
}
const sheinAppUrlAndroid = {
  'default': 'https://play.google.com/store/apps/details?id=com.zzkko'
}
const rwAppUrlAndroid = {
  'rwmfr': 'https://play.google.com/store/apps/details?id=com.romwe&hl=fr',
  'rwmes': 'https://play.google.com/store/apps/details?id=com.romwe&hl=es',
  'rwmde': 'https://play.google.com/store/apps/details?id=com.romwe&hl=de',
  'default': 'https://play.google.com/store/apps/details?id=com.romwe'
}

export {
  sheinAppStore,
  sheinAppUrlIos,
  rwAppStore,
  rwAppUrlIos,
  sheinAppUrlAndroid,
  rwAppUrlAndroid
};
