import { stringifyQueryString, parseQueryString } from '@shein/common-function'
import { resourceContentBackup } from '../../../eventCenter/analysis/utils'

const { SiteUID } = gbCommonInfo
export class WebPush {
  static name = 'WebPush'

  #owner = 'c06db2f5231a'
  #siteMap = {
    pwus: {
      domain: 'm.shein.com/us/',
      idSite: '20676',
      webpushid: 'web.55.aimtell.com'
    },
    pwuk: {
      domain: 'm.shein.co.uk/',
      idSite: '20890',
      webpushid: 'web.81.aimtell.com'
    },
    pwau: {
      domain: 'm.shein.com/au/',
      idSite: '20683',
      webpushid: 'web.77.aimtell.com'
    },
    pwfr: {
      domain: 'm.shein.com/fr/',
      idSite: '20684',
      webpushid: 'web.49.aimtell.com'
    },
    pwde: {
      domain: 'm.shein.com/de/',
      idSite: '20685',
      webpushid: 'web.78.aimtell.com'
    }
  }

  constructor() {
    const account = this.#siteMap[SiteUID]
    this.active = !!account
    if (!this.active) return

    const _at = {
      ...account,
      worker: '/apps/aimtell/assets/js/aimtell-worker.js.php',
      owner: this.#owner,
      attributes: {},
      track() {
        _at.track.q.push(arguments)
      },
    }
    _at.track.q = []
    window._at = _at
  }

  init() {
    this._landing()

    return [{
      TPM_Ele_Type: 'script',
      src: '//s3.amazonaws.com/cdn.aimtell.com/trackpush/trackpush.min.js'
    }]
  }

  _landing() {
    if (!window.history?.pushState) return

    try {
      const query = parseQueryString(location.search) || {}
      if (query?.utm_source !== 'webpush') return
      const webPushIci = resourceContentBackup()
      if (!webPushIci) return
      history.replaceState(null, null, `${location.origin}${location.pathname}?${stringifyQueryString({ queryObj: query })}`)
    } catch (error) {
      console.error(error)
    }
  }
}
