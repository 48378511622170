
// 声明式埋点通用解决方案
// 适用场景 同步点击、异步点击
import saModule from './sa'
import { debounce } from '@shein/common-function'

let uuid = 0

const ASYNC_CLICK_EVENT = 'asyncClick'
const SYNC_CLICK_EVENT = 'syncClick'
const ADD_BAG_EVENT = 'addBag'

export default class DeclarativeAnalysis {
  // 命名空间
  NAMESPACE = 'DA-'
  viewedList = []
  productList = []
  waitViewList = []
  init () {
    setTimeout(() => {
      this.bindEvent()
    }, 3000)
    $(document.body).on('click', e => {
      const $target = $(e.target).closest(`[${this.NAMESPACE}type]`)
      const target = $target[0]

      // 发送渠道
      const triggerMode = this.getTrack($target)

      if (target) {
        requestIdleCallback(() => {
          const type = $target.attr(this.NAMESPACE + 'type')
          if (type == ASYNC_CLICK_EVENT) {
            if (!$target.attr(this.NAMESPACE + 'id')) {
              this.createMutationObserver(target)
            }
          }
          if (type == SYNC_CLICK_EVENT) {
            if (triggerMode.includes('sa')) {
              saModule.click(this.getSaAttrs(target, ['name', 'param', 'beacon']))
            }
          }
          if (type == ADD_BAG_EVENT) {
            if (!$target.attr(this.NAMESPACE + 'id')) {
              this.createMutationObserver(target)
            }
          }
        })
      }
    })
    $(window).scroll(
      debounce({
        func: () => {
          this.readyScroll()
          this.scrollFunc()
        },
        wait: 200
      })
    )
  }

  getTrack ($target) {
    // 发送渠道
    const triggerMode = []
    if ($target.attr(this.NAMESPACE + 'action')) {
      if (window.GA_REMOVE_TIP) {
        window.GA_REMOVE_TIP('DA-[action|category|label|value]', $target)
      }
    }
    if ($target.attr(this.NAMESPACE + 'sa-name')) {
      triggerMode.push('sa')
    }
    return triggerMode
  }

  bindEvent () {
    // 初始化指令声明
    $(`[${this.NAMESPACE}type="${ASYNC_CLICK_EVENT}"]`).each((index, item) => {
      this.createMutationObserver(item)
    })
    // $(`[${this.NAMESPACE}view-type="${ASYNC_VIEW_EVENT}"]`).each((index, item) => {
    //     this.createMutationObserver(item)
    // })
  }

  // 同步点击发送宿主元素
  syncClickEventCtx = {}
  // 异步点击发送宿主元素
  asyncClickEventCtx = {}
  createMutationObserver (targetNode) {
    const config = {
      attributes: true
      // childList: true,
      // subtree: true
    }
    const mutationCallback = mutationsList => {
      for (const mutation of mutationsList) {
        const type = mutation.type
        switch (type) {
          case 'childList':
            // console.log("A child node has been added or removed.");
            break
          case 'attributes':
            if (mutation.attributeName == this.NAMESPACE.toLowerCase() + 'value' && $(targetNode).attr(this.NAMESPACE.toLowerCase() + 'value') !== '') {
              // 发送渠道
              const triggerMode = this.getTrack($(targetNode))
              if (triggerMode.includes('sa')) {
                const attrs = this.getSaAttrs(targetNode, ['name', 'param', 'beacon'])
                saModule.click(attrs)
              }
            }
            break
          case 'subtree':
            // console.log(`The subtree was modified.`);
            break
          default:
            break
        }
      }
    }
    const observer = new MutationObserver(mutationCallback)
    $(targetNode).attr(this.NAMESPACE + 'id', uuid++)
    observer.observe(targetNode, config)
    return observer
  }

  // 获取 sa 发送的属性
  getSaAttrs (target, attrs) {
    const $target = $(target)
    return attrs.reduce((curr, attr) => {
      const attrName = this.NAMESPACE + 'sa-' + attr
      const attrValue = $target.attr(attrName)
      if (attrValue) {
        curr[attr] = attrValue
      }
      return curr
    }, {})
  }

  // debounce (fn, timer) {
  //   var timeout
  //   return function () {
  //     var ctx = this
  //     var args = arguments
  //     if (timeout) clearTimeout(timeout)
  //     timeout = setTimeout(function () {
  //       fn.call(ctx, args)
  //     }, timer || 200)
  //   }
  // }

  readyScroll () {
    const elements = $(`[${this.NAMESPACE}view-type]`)
    const viewEle = this.filterElement(elements)
    viewEle.forEach((element) => {
      if (element) {
        const modulType = $(elements).attr(`${this.NAMESPACE}modul-type`)
        switch (modulType) {
          case 'other': // 普通曝光
            this.otherView(element)
        }
      }
    })
  }

  filterElement (elements) {
    const top = $(window).scrollTop()
    const left = $(window).scrollLeft()
    const right = left + $(window).width()
    const bottom = top + $(window).height()
    const viewEles = []
    $.each(elements, (i, element) => {
      var elTop = $(element).offset().top
      var elLeft = $(element).offset().left
      var elRight = elLeft + $(element).width()
      var elBottom = elTop + $(element).height()

      var isIntersect = !(elLeft > right ||
				elRight < left ||
				elTop > bottom ||
				elBottom < top)

      if (isIntersect) {
        viewEles.push(element)
      }
    })

    return viewEles
  }

  otherView (element) {
    const $target = $(element)
    const triggerMode = []
    if ($target.attr(this.NAMESPACE + 'action')) {
      triggerMode.push('ga')
    }
    if ($target.attr(this.NAMESPACE + 'sa-name')) {
      triggerMode.push('sa')
    }
    if (triggerMode.includes('sa')) {
      saModule.view(this.getSaAttrs(element, ['name', 'param', 'beacon']))
    }
    $(element).removeAttr(`${this.NAMESPACE}view-type`)
  }

  scrollFunc () { // 普通曝光埋点
    const documentOffsetHeight = document.body.offsetHeight
    $(`[${this.NAMESPACE}view-type]`).each((i, element) => {
      var $this = $(element)

      if ($this.attr(`${this.NAMESPACE}view-type`) == 'ordinary' && $this[0].getBoundingClientRect().top + 15 <= documentOffsetHeight) {
        this.ordinaryView(element)
      }
    })
  }

  ordinaryView (element) {
    const $target = $(element)
    const triggerMode = []
    let viewNoParam = false // 曝光不需要参数
    if ($target.attr(this.NAMESPACE + 'sa-name')) {
      triggerMode.push('sa')
      viewNoParam = $target.attr(this.NAMESPACE + 'view-no-param')
    }
    if (triggerMode.includes('sa')) {
      saModule.view(this.getSaAttrs(element, viewNoParam ? ['name'] : ['name', 'param']))
    }
    $(element).removeAttr(`${this.NAMESPACE}view-type`)
    $(element).attr('el-viewed', true)
  }
  // goodsListView(element) {
  //     //item DA-goods-params="{spu,sku,cat_id,goods_id,price,index}"
  //     let childList = element.children
  //     let els
  //     if(childList.length > 0) {
  //         els = this.filterElement(childList)
  //         els.forEach
  //     }
  //     let params = $target.attr(`${this.NAMESPACE}goods-params`)
  //     if(!params) return
  //     let paramsObj = JSON.parse(params)
  //     let {saObj, gaObj, viewedList, waitViewList} = this
  //     if (!viewedList.includes(goods_id) && waitViewList.length < 16) {

  //     }
  // }
}
