
const { LAYOUT } = gbCommonInfo
$(() => {
  requestIdleCallback(() => {
    if (LAYOUT === 'app') return
    // 按需加载
    const isLoaded = {
      category: false,
      search: false
    }
    const proxyEl = '.j-common-logo-header'
    $(proxyEl).on('click', '.j-dt-go-category', () => {
      if (!isLoaded.category) {
        isLoaded.category = true
        import(/* webpackChunkName: "mpa-sidecategory" */ './category')
      }
    })
  })
})
