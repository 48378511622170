import { parseQueryString, isIPhoneX } from '@shein/common-function'

window.GB_ANALYSIS_GA = ''
window.GB_ANALYSIS_GA_SET = ''
window.GB_ANALYSIS_GA_SEND = ''
window.GB_ga_currentRate = 1
window.PageGroup = typeof PageGroup !== 'undefined' ? PageGroup : 'other'
window.PageGroupOverview = ''
window.gbWishlistAbtStr = ''


const pageView = () => {
  // 统计用户从主屏幕桌面图标进入/浏览器普通进入
  if (window.SHEIN_COMMON) {
    var _from = parseQueryString(location.search).from
    var _fullscreen = sessionStorage.getItem('pwa-fullscreen')

    if (_from == 'fullscreen' || _fullscreen) {
      sessionStorage.setItem('pwa-fullscreen', 1)

      if (isIPhoneX()) {
        $('body').addClass('ipx-fullscreen')
      }
    } else {
      $('meta[name=viewport]').attr(
        'content',
        $('meta[name=viewport]').attr('content') + ', viewport-fit=cover'
      )
      $('body').addClass('no-fullscreen')
    }
  }

  // 有些业务埋点 (eg:隐私政策)期望在page view之后发送
  appEventCenter.$emit('after_page_view')

  if (window.branchIsbindExpose) {
    appEventCenter.$emit('exposeBranch').$off('exposeBranch')
    window.branchIsbindExpose = false
  } else {
    window.branchIsbindExpose = true
    appEventCenter.$on('exposeBranch', function () {
      setTimeout(() => {
        sa('send', { activity_name: 'expose_popup_branch', activity_param: {} })
      }, 0)
    })
  }
}

export {
  pageView
}
