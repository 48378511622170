import UAParser from 'ua-parser-js'
export function tpmRecord(params, options = {}) {
  if (typeof window === 'undefined') return
  window.TPM?.run({
    marketing: 'ClientAnalytics',
    method: '_defineTrack',
    params: { data: params, options }
  })
}

const getBsVersion = tag => {
  const ua = navigator.userAgent.toLocaleLowerCase()
  const browser = ua
    .split(' ')
    ?.find?.(item => item.indexOf(tag) > -1)
    ?.split?.('/')
  return browser?.[1] || getBsVersion('chrome')
}

export function getBrowserInfo() {
  const ua = getUaParser()
  if (ua.browser.name?.toLocaleLowerCase?.()?.includes?.('safari')) {
    getBsVersion('safari')
    return {
      name: ua.browser.name,
      version: getBsVersion('safari')
    }
  } else {
    return {
      name: ua.browser.name,
      version: ua.browser.version
    }
  }
}

export function getUaParser() {
  return new UAParser().getResult() //依赖common.js
}
