import { v4 as uuidv4 } from 'uuid'
import { parseQueryString } from '@shein/common-function'
const { REPORT_URL, IS_RW, langPath } = gbCommonInfo
const { MARKETING_URL } = REPORT_URL

// 广告投放分流埋点
const advertisingSendParams = adrtb => {
  if (IS_RW || (location.pathname != `${langPath}/global/marketing.html`)) return {}
  if (adrtb == 1) return { ad_abt_request: 1, ad_request_result: 'success' }
  if (adrtb == 2) return { ad_abt_request: 1, ad_request_result: 'timeout' }
  if (adrtb == 3) return { ad_abt_request: 1, ad_request_result: 'fail' }
  return { ad_abt_request: 0, ad_request_result: '' }
}

// 营销归因埋点发送:用户着陆
const sendMdmpUserLanding = () => {
  try {
    const { url_from = '', aff_id = '', onelink = '', scene = '', campaign = '', journey_name = '', deeplink = '', requestId = '', adrtb = 0 } = parseQueryString(location.search)
    if (url_from || aff_id || onelink || scene === 'onelink') {
      const reportUrl = `${MARKETING_URL}/mdmpUserLanding`
      const commonParams = {
        uuid: uuidv4(),
        onelink: onelink || journey_name,
        reffer: location.href,
        campaign,
        journey_name,
        scene,
        deeplink,
        channel: url_from,
        ...advertisingSendParams(adrtb)
      }
      window.sa('error', reportUrl, {
        activity_name: 'Landing',
        requestId,
        ...commonParams
      })
      window.sa('send', {
        activity_name: 'click_marketing_url_landing',
        activity_param: {
          referer: encodeURIComponent(window.location.href),
          traffic_source: document.referrer || '',
          bu_department: 'marketing',
          ...commonParams
        }
      })
      // 错误要上报的
      if (onelink && SaPageInfo && SaPageInfo.page_name === 'page_other') {
        window.sa('error', `${MARKETING_URL}/mdmpMarketingWebReport`, {
          activity_name: 'click_onelink_web_error',
          activity_param: {
            bu_department: 'marketing'
          },
          bu_department: 'marketing',
          onelink: onelink || journey_name,
        })
      }
    }
  } catch (e) { console.log(e) }
}

sendMdmpUserLanding()
