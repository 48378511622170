import { nextIdle, isPixel } from './preprocessing/utils'
export class EventCenter {
  #supportedScenes = ['pageview', 'viewhome', 'viewlist', 'viewcontent', 'viewcart', 'addcart', 'addwish', 'signup', 'checkout', 'placeorder', 'purchase', 'subpurchase']
  #scenesNeedSentImmediate = ['placeorder']
  #fireable = false
  #TPM = null

  constructor(TPM) {
    this.#TPM = TPM
    this.eventPools = {}

    this.#supportedScenes.forEach(scene => {
      this.eventPools[scene] = {
        subscribers: {},
        events: []
      }
    })
  }

  publish(scene, event) {
    this.eventPools[scene].events.push(event)
    this.#TPM.log('publish', { scene, event })
    if (this.#fireable) {
      this.#TPM.log('fireScene in publish', { scene })
      this.fireScene(scene)
    }
  }

  subscribe(name, marketing) {
    this.#supportedScenes.forEach(scene => {
      marketing[scene] && (this.eventPools[scene].subscribers[name] = marketing)
    })
  }

  async fireAll() {
    this.#TPM.log('fireAll')
    this.#fireable = true
    await Promise.all(Object.keys(this.eventPools).map(scene => this.fireScene(scene)))
    this.#TPM.log('fireAll complete')
  }

  async fireScene(scene) {
    const sceneObj = this.eventPools[scene]
    if (!sceneObj.events.length) return
    const fireSync = this.#scenesNeedSentImmediate.includes(scene)

    let pixelList = []

    const tasks = sceneObj.events.map(event => {
      return Object.values(sceneObj.subscribers).map(async subscriber => {
        !fireSync && await nextIdle()
        this.#TPM.log('fireScene', { scene, event, subscriber })
        const pixels = subscriber[scene](event)
        isPixel(pixels) && (pixelList = pixelList.concat(pixels))
      })
    })
    sceneObj.events = []

    await Promise.all(Array.prototype.concat(...tasks))
    this.#TPM.log('fireScene complete', scene)

    pixelList.length && this.#TPM.appendDom({
      domDataList: pixelList,
      comment: scene
    })
  }
}
