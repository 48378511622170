const { PUBLIC_CDN } = gbCommonInfo
export class IScroll {
  static name = 'IScroll'

  #IScroll = null

  _getIScroll() {
    if (this.#IScroll) return { datas: this.#IScroll }

    let resolve
    this.#IScroll = new Promise(r => resolve = r)

    return {
      datas: this.#IScroll,
      pixels: [{
        TPM_Ele_Type: 'script',
        TPM_Config: {
          CDN: true
        },
        src: `${PUBLIC_CDN}/pwa_dist/libs/iscroll/iscroll-probe.js`,
        onload: () => resolve(window.IScroll)
      }]
    }
  }
}
