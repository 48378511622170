const { WEB_CLIENT, lang } = gbCommonInfo
export class Fitanalytics {
  static name = 'Fitanalytics'

  #List = ['de', 'uk', 'nl', 'en', 'ar', 'au', 'us', 'in', 'il', 'ilen', 'id', 'iden', 'vn', 'th', 'then', 'fr', 'ca', 'cafr', 'it', 'br', 'ru', 'es', 'mx', 'cl', 'arg', 'se', 'sesv', 'tw', 'hk', 'tr', 'eur', 'eurfr', 'eurde', 'eurnl', 'eurpt-pt', 'asia', 'ar-en']
  #PDP_LOAD = false

  constructor() {
    this.active = this.#List.includes(lang)
  }

  purchase() {
    return [{
      TPM_Ele_Type: 'script',
      src: `//integrations.fitanalytics.com/shop/${WEB_CLIENT}/orderconfirmation.js`
    }]
  }

  _loadPdp() {
    if (this.#PDP_LOAD) return
    this.#PDP_LOAD = true

    // hack:: Fitanalytics pdp轮询代码 与 Criteo 有牵扯
    if (!window.criteo_q) window.criteo_q = []

    return {
      pixels: [{
        TPM_Ele_Type: 'script',
        src: `//integrations.fitanalytics.com/shop/${WEB_CLIENT}/pdp.js`,
        id: 'integrations-script',
      }]
    }
  }
}
