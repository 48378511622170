// import { checkSupportedHook } from './compatibilityCore'

// /**
//  * 验证用户画像提升效率。后期删除
//  */
//  const sendSaAnalysis = async () => {
//     let { default: fingerPrint } = await import(/* webpackChunkName: "device-finger-print" */ './index')
  
//     Promise.all([
//       await fingerPrint.get(),
//       await window._GB_DeviceFingerPrint.callFuncPromise()
//     ]).then((result) => {
//       let fingerPrint2 = result[0]
//       let devicesShumei = window._GB_DeviceFingerPrint.smDeviceId
  
//       sa('send', {
//         activity_name: 'expose_browser_fingerprint',
//         activity_param: {
//           fingerprint_id: fingerPrint2.value,
//           fingerprintShumei_id: devicesShumei
//         }
//       })
//     })
//   }
  
//   if (typeof window !== 'undefined' && typeof performance !== undefined) {
//     if (performance.timing.loadEventStart > 0) {
//       sendSaAnalysis()
//     } else {
//       window.addEventListener('load', () => {
//         sendSaAnalysis()
//       })
//     }
//   }

// 借此地做数据验证！！！！

// webp 支持率数据以采集完成，目前不在进行采集
// function check_webp_feature(feature, callback) {
//   var kTestImages = {
//     lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
//     lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
//     alpha: 'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
//     animation: 'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
//   }
//   var img = new Image()
//   img.onload = function () {
//     var result = (img.width > 0) && (img.height > 0)
//     callback(feature, result)
//   }
//   img.onerror = function () {
//     callback(feature, false)
//   }
//   img.src = 'data:image/webp;base64,' + kTestImages[feature]
// }


// 兼容性回收
function testCompatibility(){
  const FINGER_PRINT_TEST_KEY = 'FINGER_PRINT_TEST_V3'
  const CACHE_TIME = 86400000 * 7
  let CACHE_FINGER_INFO = null
  try {
    CACHE_FINGER_INFO = JSON.parse(localStorage.getItem(FINGER_PRINT_TEST_KEY))
    
    if (CACHE_FINGER_INFO == null || Date.now() - CACHE_FINGER_INFO.time >= CACHE_TIME) {
      // 没有设置 => get && set cache
      let cacheFingerInfo = {
        visiterId: '',
        time: Date.now() + CACHE_TIME
      }
      localStorage.setItem(FINGER_PRINT_TEST_KEY, JSON.stringify(cacheFingerInfo))
  
      if(typeof window !== 'undefined'){
        // 判断浏览器是否支持 PointerEvent
        const supportsPointerEvent = window.PointerEvent ? 1 : 0
        sa('send', {
          activity_name: 'expose_pointer_event_compatibility',
          activity_param: {
            supportsPointerEvent
          }
        })
      }
      
    }
  } catch(e) {
    console.log(e)
  }
}

testCompatibility()
// checkSupportedHook()
