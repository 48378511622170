import apiReport from 'public/src/pages/common/apiReport'
import schttp from 'public/src/services/schttp'
const { NODE_SERVER_ENV, SiteUID } = gbCommonInfo
const isProd = !['localhost', 'debug'].includes(NODE_SERVER_ENV)

export class Cybersource {
  static name = 'Cybersource'
  #merchantId = ''
  #sessionId = ''
  #orgId = ''
  #cybersourceLoaded = false

  async _loadCybersource() {
    const params = await schttp({
      url: '/api/checkout/cybsMerchant/get'
    })
      .then(res => {
        if (res?.code != 0 || !res?.info?.merchant) return

        window.GB_cybersource_uuid = res.info.uuid
        return {
          merchant_id: res.info.merchant,
          org_id: res.info.orgId,
          session_id: res.info.uuid,
        }
      })
      .catch(() => { })
    if (!params) return
    console.log('cybersource init!')

    const { merchant_id = 'shein_risk_manager', session_id = '', org_id = isProd ? 'k8vif92e' : '1snn5n9w' } = params
    if (this.#merchantId == merchant_id && this.#sessionId == session_id && this.#orgId == org_id && this.#cybersourceLoaded) {
      return
    }
    this.#merchantId = merchant_id
    this.#sessionId = session_id
    this.#orgId = org_id
    let url = '//h.online-metrix.net/fp/tags.js?org_id=' + org_id + '&session_id=' + merchant_id + session_id

    return {
      pixels: [{
        TPM_Ele_Type: 'script',
        src: url,
        onload: () => {
          window.GB_cybs_loaded = true
          this.#cybersourceLoaded = true
        },
        onerror: () => {
          // js初始化失败
          apiReport.report({
            apiPath: 'cybs/before-payment/error',
            siteuid: SiteUID,
            scene: 'js sdk init fail',
            cybsDeviceId: session_id
          })
        }
      }]
    }
  }
}
