const { WEB_CLIENT, lang, NODE_SERVER_ENV } = gbCommonInfo
export class Emarsys {
  static name = 'Emarsys'

  #Subdomain = NODE_SERVER_ENV === 'production' ? '//recommender' : '//cdn'
  #SH_Map = {
    en: '14AEFABFF6C30DEA',
    fr: '19DB2374A86E66CB',
    de: '1BBEDD499A9531EB',
    es: '1D243573F433C16B',
    uk: '168D45E4CC9D9A30',
    ar: '12772D590FB8B485',
    'ar-en': '12772D590FB8B485',
    au: '10ECBA3DB5F814A5',
    in: '17BB651AD5A11623',
    us: '15306811EE8118BB',
    ca: '1A69C9CE8026BBCF',
    cafr: '1A69C9CE8026BBCF',
    il: '1D572EBCE2FD916D',
    ilen: '1D572EBCE2FD916D',
    it: '17581E4E3BE0039E',
    mx: '185B89E4CD55F346',
    nl: '15222E04E24ADCDA',
    tw: '13459D4EDBB8F6E7',
    ru: '1417FB44A25ABAFD',
    se: '19EEECFE3D868F07',
    sesv: '19EEECFE3D868F07',
    eur: '1C3C6FDBF34C5A79',
    eurfr: '1C3C6FDBF34C5A79',
    eurnl: '1C3C6FDBF34C5A79',
    'eurpt-pt': '1C3C6FDBF34C5A79',
    br: '1290FBB9BA4228EE',
  }
  #RW_Map = {
    en: '1F542F7522AA01B3',
    us: '14E90776A136245E',
    fr: '1204911E0DEAF144',
    es: '120F91DC149D7D53',
    uk: '1B1B4DB0BD97D990'
  }
  #DOM_ID = 'scarab-js-api'

  init() {
    const ID = WEB_CLIENT === 'shein'
      ? this.#SH_Map[lang] || this.#SH_Map['en']
      : this.#RW_Map[lang] || this.#RW_Map['en']

    return [{
      TPM_Ele_Type: 'script',
      src: this.#Subdomain + '.scarabresearch.com/js/' + ID + '/scarab-v2.js',
      id: this.#DOM_ID
    }]
  }
}

