class TestTrackerTime {
  constructor() {
    this.data = []
    this.timer = null
  }

  record(data = []) {
    this.data = data.map(item => {
      return {
        key: item.key_path,
        value: item.values.num
      }
    })
    this.displayData()
  }

  /**
   * @description: 展示内容在页面中
   */
  displayData() {
    clearTimeout(this.timer)
    this.timer = setTimeout(() => {
      const arr = this.data
      const divEl = document.createElement('div')
      divEl.style.position = 'fixed'
      divEl.style.top = '10%'
      divEl.style.left = '10%'
      divEl.style.backgroundColor = 'rgba(0,0,0,0.5)'
      divEl.style.color = '#fff'
      divEl.style.padding = '10px'
      divEl.style.zIndex = '9999'
      divEl.style.transform = `translate3d(0,0,9999px)`
      divEl.style.borderRadius = '5px'

      const innerHTML = arr
        .map(item => {
          return `<div style="padding:2px 0;font-size: 12px;">${item.key}: ${item.value}</div>`
        })
        .join('')

      divEl.innerHTML = innerHTML
      document.body.appendChild(divEl)
      setTimeout(() => {
        document.body.removeChild(divEl)
      }, 3000)
    }, 1000)
  }
}

const testTrackerTime = new TestTrackerTime()

export { testTrackerTime }
