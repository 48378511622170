/**
 * @ Author: Your name
 * @ Create Time: 2021-01-07 17:47:28
 * @ Modified by: Your name
 * @ Modified time: 2021-05-06 20:43:27
 * @ Description:  打开app
 * vue中使用指令 v-call-app
 * 也可以使用 vue 原型链上的 $callApp 构造器
 * 指令参数
 * 绑定data，可选
 * data {
 *  data: {}, // deeplink中的额外参数
 *  deeplink: '', // 覆盖默认deeplink
 *  before(shortData, wakeupData) => void 0， // 可以返回一个 Promise方法，在打开app前调用，如sa，ga上报，获取剪切板参数等都在这里完成，shortData剪切板数据，wakeupData指令绑定的data
 *  sa: () => void 0, // sa
 *  ga: () => void 0, // ga
 *  defaultSa: Boolean // false  不发送默认sa和ga
 * }
 * 常规方法使用：
 * const callApp = new CallApp()
 * callApp.bindClick(${dom}) // 真机复制到剪切板必须由用户触发一个事件，要绑定按钮
 *
 * // 打开app操作
 * callApp.wakeup()
 */

import md5 from 'md5'
import Vue from 'vue'
import Clipboard from 'clipboard'
import { InitFtoken } from 'public/src/pages/common/ftoken/index.js'
import schttp from 'public/src/services/schttp'
const u = navigator.userAgent
const isAndroid = u.indexOf('Android') > -1 || u.indexOf('Linux') > -1 // android
const isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // iOS
import * as appUrlConfig from './../../../../../configs/appUrlConfig'
const { WEB_CLIENT, SiteUID, REPORT_URL } = gbCommonInfo
const { SA_REPORT_URL, MARKETING_URL } = REPORT_URL
const defaultJourneyName = '' // 不是branch进入的app，使用默认journey
const factory = []
const reportUrl = `${SA_REPORT_URL}/mdmpMarketingWebReport`
const reportMarkUrl = `${MARKETING_URL}/mdmpMarketingWebReport`
import { getCookie, getQueryString } from '@shein/common-function'
import { getBrowserInfo, getUaParser } from './utils'
let timer
document.addEventListener('visibilitychange', () => {
  clearInterval(timer)
})

class CallApp {
  fingerKey = ''
  sclid = this.createRandom()
  appDownloadUrl = ''
  scene = ''
  copyHandle = null
  shortData = {}
  wakeupData = {}
  constructor() {
    factory.push(this)
    const prefix = WEB_CLIENT === 'shein' ? WEB_CLIENT : 'rw'
    if (isiOS) {
      this.appDownloadUrl =
        appUrlConfig[prefix + 'AppUrlIos'][SiteUID] || appUrlConfig[prefix + 'AppUrlIos'].default
    }
    if (isAndroid) {
      this.appDownloadUrl =
        appUrlConfig[prefix + 'AppUrlAndroid'][SiteUID] ||
        appUrlConfig[prefix + 'AppUrlAndroid'].default
    }
    InitFtoken(key => {
      this.fingureKey = key
      factory.forEach(e => (e.fingerKey = key))
    })
  }
  // 获取剪贴板格式
  async getClipboardContent() {
    const activityId = getQueryString({ key: 'activityId' })
    if (activityId) {
      const res = await schttp({
        method: 'POST',
        url: '/api/link/deeplink/getStyleString',
        data: {
          activityId
        }
      })
      return () => res.info?.info?.styleString
    } else {
      return () => this.createKey()
    }
  }
  createRandom() {
    try {
      return md5(Date.now() + +Math.random().toString().replace(/.*\./, '') + getCookie('cookieId'))
    } catch (e) {
      const strFn = () =>
        Date.now() + '' + Math.random().toString(34).replace(/.*\./, '') + getCookie('cookieId')
      return (strFn() + strFn() + strFn()).substr(-32)
    }
  }
  createValue(wakeupData = {}) {
    const data = wakeupData.data || {}
    this.wakeupData = wakeupData
    const meta = document.querySelector('meta[name^="branch:deeplink:"]')
    let content =
      wakeupData.deeplink ||
      (meta && meta.getAttribute('content')) ||
      WEB_CLIENT + 'link://applink/wakeup'
    if (!/applink\/\w+/.test(content)) {
      content = content.replace(/\/applink/, '/applink/wakeup')
    }
    const params = content.replace(/.*data=/, '')
    let parse = null
    try {
      const tmp = (params && decodeURIComponent(params)) || null
      parse = (tmp && JSON.parse(tmp)) || {}
    } catch (e) {
      parse = {}
    }
    const appendData = Object.assign(
      parse,
      {
        url_from: this.urlFrom,
        // utm_campaign: 'M-APP',
        sclid: this.sclid,
        scene: 'm-app_banner'
      },
      data
    )
    return (
      content.replace(/data=.*$/, '') +
      (/\?/.test(content) ? '' : '?') +
      'data=' +
      encodeURIComponent(JSON.stringify(appendData))
    )
  }
  isSafari() {
    var ua = navigator.userAgent.toLowerCase()
    if (
      ua.indexOf('applewebkit') > -1 &&
      ua.indexOf('mobile') > -1 &&
      ua.indexOf('safari') > -1 &&
      ua.indexOf('linux') === -1 &&
      ua.indexOf('android') === -1 &&
      ua.indexOf('chrome') === -1 &&
      ua.indexOf('ios') === -1 &&
      ua.indexOf('browser') === -1
    ) {
      return true
    } else {
      return false
    }
  }
  async saveRemoteSync(obj) {
    await schttp({
      method: 'POST',
      url: '/api/journeyBranch/tiny/create',
      data: {
        ...obj,
        key: obj.key.replace(/.*\//, '').toLowerCase()
      }
    })
  }
  createKey() {
    const linkPrefix = this.scene === 'onelink' ? 'onelink' : 'm-app'
    return (
      (WEB_CLIENT === 'shein'
        ? `https://shein.top/${linkPrefix}-`
        : `https://fun.romwe.com/${linkPrefix}-`) + this.sclid
    )
  }
  // 上报设备指纹
  async reportFt(onelink) {
    // 上报设备指纹参数用于模糊归因
    const browserInfo = getBrowserInfo()
    const ua = getUaParser()
    return schttp({
      url: '/api/link/deeplink/reportActionEnv',
      method: 'post',
      data: {
        activityId: getQueryString({ key: 'activityId' }),
        browserNm: browserInfo.name,
        browserVersion: browserInfo.version,
        cookieId: getCookie('cookieId'),
        device: ua.device.model,
        effectiveType: navigator.connection?.effectiveType || '',
        externalParam: '',
        language: navigator.language,
        onelink,
        osNm: ua.os?.name?.toLocaleLowerCase?.(),
        osVersion: ua.os.version,
        request_body2: location.href,
        screenPixel: window.screen.width * (Math.round(window.devicePixelRatio) || 1) + '*' + window.screen.height * (Math.round(window.devicePixelRatio) || 1),
        siteTp: WEB_CLIENT,
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        userAgent: navigator.userAgent
      }
    })
  }
  async wakeup(wakeupData) {
    const {
      before = () => void 0,
      sa: _sa = () => void 0,
      ga: _ga = () => void 0
    } = wakeupData || {}
    if (wakeupData && wakeupData.preRequest !== true) {
      this.shortData = this.getShortData(wakeupData)
      await this.saveRemoteSync(this.shortData)
    }
    typeof _sa === 'function' && _sa()
    typeof _ga === 'function' && _ga()

    // debugger
    try {
      await before(this.shortData, wakeupData)
    } catch (e) {
      console.error(e)
    }
    this.sclid = this.createRandom()
    const referrerKey = isAndroid
      ? encodeURIComponent(`utm_shein_onelink=${this.shortData.key}`)
      : this.shortData.key
    const targetUrl =
      this.appDownloadUrl.indexOf('?') > -1
        ? `${this.appDownloadUrl}&referrer=${referrerKey}`
        : `${this.appDownloadUrl}?referrer=${referrerKey}`

    const { info: { onelink } = {} } =
      (await schttp({
        url: '/api/common/onelink/get',
        method: 'post',
        data: {
          brand: WEB_CLIENT === 'shein' ? 7 : 9,
          businessLineId: 5,
          deeplink: this.shortData.value,
          genType: 0,
          mobileType: 3,
          system: 'linkService',
          webType: 1,
          androidUrl: targetUrl,
          iosUrl: targetUrl
        }
      })) || {}

    //新增onelink埋点上报
    let params = {}
    if (wakeupData && wakeupData.saPrams && wakeupData.saPrams.scene === 'onelink') {
      params = {
        activity_name: 'click_onelink_web_install',
        activity_param: {
          reffer: wakeupData.saPrams.reffer || '',
          deeplink: this.shortData.value,
          channel: wakeupData.saPrams.url_from || '',
          paste_key: this.shortData.key,
          journey_name: wakeupData.saPrams.journey_name || '',
          sclid: this.sclid,
          scene: wakeupData.saPrams.scene,
          campaign: wakeupData.saPrams.campaign || '',
          finger_print_key: this.fingerKey,
          onelink: wakeupData.saPrams.journey_name || ''
        }
      }
      sa('send', params, {
        _url: reportMarkUrl
      })
    } else {
      // 保留旧逻辑上报
      params = {
        activity_name: 'click_M_APP_Banner_Button',
        activity_param: {
          journey_id: this.urlFrom,
          paste_key: this.shortData.key,
          deeplink: this.shortData.value,
          finger_print_key: this.fingerKey,
          onelink
        }
      }
      if (wakeupData.defaultSa !== false) {
        sa('send', params)
        sa('send', params, {
          _url: reportUrl
        })
      }
    }

    if (onelink) {
      await this.reportFt(onelink)
      // 延迟下保证请求发出去
      window.location.href = onelink
    } else {
      const delayTime = wakeupData.preRequest ? 50 : 3000
      if (isAndroid) {
        const referrerKey = encodeURIComponent(`utm_shein_onelink=${this.shortData.key}`)
        const targetUrl =
          this.appDownloadUrl.indexOf('?') > -1
            ? `${this.appDownloadUrl}&referrer=${referrerKey}`
            : `${this.appDownloadUrl}?referrer=${referrerKey}`
        timer = setInterval(() => {
          clearInterval(timer)
          if (!this.isHide) {
            location.href = targetUrl
          }
        }, delayTime)
        location.href = this.shortData.value
      } else if (isiOS) {
        const targetUrl =
          this.appDownloadUrl.indexOf('?') > -1
            ? `${this.appDownloadUrl}&referrer=${this.shortData.key}`
            : `${this.appDownloadUrl}?referrer=${this.shortData.key}`
        timer = setInterval(() => {
          clearInterval(timer)
          if (!this.isHide) {
            if (this.isSafari() && !wakeupData.preRequest) {
              location.href = '/api/journeyBranch/appStore/get?url=' + encodeURIComponent(targetUrl)
            } else {
              window.open(targetUrl, '_blank')
            }
          }
        }, delayTime)
        if (!wakeupData.preRequest) {
          // 非权益弹窗场景 先跳转短链
          location.href = this.shortData.value
        }
      }
    }
  }
  async bindClick(dom = void 0) {
    const content = await this.getClipboardContent()
    dom =
      O(dom) === 'string' ? document.querySelector(dom) : /html\w+element/.test(O(dom)) ? dom : null
    if (!dom && !document.querySelector(dom)) {
      return
    }
    try {
      this.copyHandle && this.copyHandle.destroy()
    } catch (e) {
      console.log(e)
    }

    this.copyHandle = new Clipboard(dom, {
      text: content
    })
    this.copyHandle.on('success', () => {
      console.log('复制成功')
    })
    this.copyHandle.on('error', () => {
      console.log('复制失败')
    })
  }
  async setSence(wakeupData) {
    if (wakeupData && wakeupData.saPrams && wakeupData.saPrams.scene) {
      this.scene = wakeupData.saPrams.scene
    }
    // 保存长短链提前
    if (wakeupData && wakeupData.preRequest) {
      this.shortData = this.getShortData(wakeupData)
      await this.saveRemoteSync(this.shortData)
    }
  }
  destroy() {
    try {
      this.copyHandle && this.copyHandle.destroy()
    } catch (e) {
      console.log()
    }
    factory.splice(factory.indexOf(this), 0)
  }
  getShortData(wakeupData) {
    const value = this.createValue(wakeupData)
    return {
      key: this.createKey(),
      value
    }
  }
  get isHide() {
    const doc = document
    return doc.hidden || doc.mozHidden || doc.msHidden || doc.webkitHidden
  }
  get urlFrom() {
    return (this.wakeupData.data && this.wakeupData.data.url_from) || defaultJourneyName
  }
}

document.addEventListener('visibilitychange', () => {
  for (const v of factory) {
    v.sclid = v.createRandom()
  }
})

Object.defineProperties(Vue.prototype, {
  $callApp: {
    value: CallApp,
    configurable: false,
    writable: false,
    enumerable: false
  }
})
Vue.directive('call-app', {
  bind: (el, binding) => {
    const callApp = new CallApp()
    callApp.setSence(binding.value)
    if (isiOS) {
      callApp.bindClick(el)
    }
    el.callApp = callApp
    el.addEventListener(
      'click',
      () => {
        const wakeupData = binding.value
        callApp.wakeup(wakeupData)
      },
      false
    )
  },
  unbind: el => {
    el.callApp && el.callApp.destroy()
  }
})
// 没有任务时再加载脚本

function O(target) {
  return Object.prototype.toString
    .call(target)
    .replace(/\[\w+\s|\]/g, '')
    .toLowerCase()
}

export default CallApp
