
// 第三方上报异步文件加载
import './load_third'
import './nav'
import { pageView } from './ga'
import DeclarativeAnalysis from './declarative'

$(() => {
  const declarativeAnalysis = new DeclarativeAnalysis()
  declarativeAnalysis.init()
})

export {
  pageView
}
